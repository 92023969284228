import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const Layout = React.lazy(() => import('./containers/Layout'));
const Login = React.lazy(() => import('./views/public/login/Login'));

class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route 
              exact
              path="/login" 
              name="Login Page"
              render={props  =>
                localStorage.getItem("jwt")  === null   ? (
                  <Login {...props} />
                ) : (
                    <Redirect
                      to={{
                        pathname: "/",
                      }}
                    />
                  )
              }
            />
            <Route 
              path="/" 
              name="Home" 
              render={props  =>
                localStorage.getItem("jwt")  !== null   ? (
                  <Layout {...props} />
                ) : (
                    <Redirect
                      to={{
                        pathname: "/login",
                      }}
                    />
                  )
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
